<template>
  <div>
    <div ref="echart" class="echartDiv"></div>
  </div>
</template>
   
  <script>
import * as echarts from "echarts";
import { onMounted, toRefs, ref, reactive } from "vue";
export default {
  setup() {
    let state = reactive({
      xAxisData: ["1月", "2月", "3月", "4月", "5月", "6月", "7月"],
      yAxisData: [4123, 2232, 1312, 3452],
      legendData: ["入库", "实有"],
      echart: ref(),
    });
    const echartInit = () => {
      // let barWidth = 20;
      var myChart = echarts.init(state.echart);

      // 指定图表的配置项和数据
      var option = {
        title: {
          text: "",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: state.legendData,
          right: "0",
          top: "10",
          //图例文字每项之间的间隔
          itemGap: 20,
          textStyle: {
            color: "#ffffff",
            fontSize: 14,
          },
        },
        grid: {
          containLabel: false,
          left: "10%",
          top: "20%",
          bottom: "10%",
          right: "10%",
        },
        xAxis: {
          type: "category",
          data: state.xAxisData,
          axisLine: {
            lineStyle: {
              color: "#FFFFFF",
              opacity: 0.5,
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            margin: 20, //刻度标签与轴线之间的距离。
            textStyle: {
              color: "#ffffff",
            },
          },
        },
        yAxis: [
          {
            type: "value",
            axisLine: {
              show: true,
              lineStyle: {
                color: "#B5B5B5",
              },
            },
            splitLine: {
              lineStyle: {
                // 使用深浅的间隔色
                color: ["#B5B5B5"],
                type: "dashed",
                opacity: 0.2,
              },
            },
            axisLabel: {
              //   show: false,
            },
          },
          {
            type: "value",
            axisLine: {
              show: false,
              lineStyle: {
                color: "#B5B5B5",
              },
            },
            splitLine: {
              lineStyle: {
                // 使用深浅的间隔色
                color: ["#B5B5B5"],
                type: "dashed",
                opacity: 0.2,
              },
            },
            axisLabel: {
              //   show: false,
            },
          },
        ],
        series: [
          {
            name: "入库",
            type: "line",
            stack: "Total",
            data: [10, 80, 45, 200, 90, 230, 2],
            yAxisIndex: 0,
            color: "#00FF1D",
            lineStyle: {
              width: 1,
            },
            label: {
              show: true,
              position: "top",
              formatter: "{c}",
              textStyle: {
                color: "#ffffff",
              },
            },
          },
          {
            name: "实有",
            type: "line",
            stack: "Total",
            data: [45645, 4224, 1000, 5665, 45656, 456, 1521],
            yAxisIndex: 1,
            color: "#FFED00",
            lineStyle: {
              width: 1,
            },
            label: {
              show: true,
              position: "top",
              formatter: "{c}",
              textStyle: {
                color: "#ffffff",
              },
            },
          },
        ],
      };
      myChart.setOption(option);
    };

    //挂载
    onMounted(() => {
      echartInit();
    });

    return {
      ...toRefs(state),
      echartInit,
    };
  },
};
</script>
   
  <style scoped>
.echartDiv {
  width: 100%;
  height: 300px;
}
aaa {
  color: #ffeb0f;
  text-align: center;
}
</style>